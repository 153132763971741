import dynamic from 'next/dynamic';
import { useState, useRef, useEffect } from 'react';
import Brand from './elements/Brand';
const Search = dynamic(() => import('./elements/Search'));
import NavItem from './elements/NavItem';
import classNames from 'classnames';

const Desktop = ({
    links,
    remove_search_functionality,
    search_placeholder,
    logo,
}) => {
    const [active, setActive] = useState(null);
    const header = useRef();
    const handleClick = (e, id) => {
        e.preventDefault();
        if (!active) {
            setActive(id);
        }
        if (active === id) {
            setActive(null);
        }
        if (active !== id) {
            setActive(id);
        }
    };
    useEffect(() => {
        const banner = document.querySelector('.hero')?.clientHeight - 200;
        const clickedOutside = (e) => {
            if (active && !header.current.contains(e.target)) {
                setActive(false);
            }
        };
        const handleScroll = () => {
            // hides the navbar if scrolled passed banner
            if (scrollY > banner && active !== null && active !== false) {
                setActive(false);
            }
        };
        document.addEventListener('mousedown', clickedOutside);
        window.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('mousedown', clickedOutside);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [active, header]);
    return (
        <div className="navbar" ref={header}>
            <div
                className={classNames('navbar__container', {
                    'navbar__container--no-logo': !logo,
                })}
            >
                <Brand logo={logo} />
                <nav className="navbar__nav" role="navigation">
                    <ul className="navbar__list">
                        {links &&
                            links?.map((link, index) => (
                                <NavItem
                                    key={index}
                                    link={link}
                                    active={active}
                                    handleClick={handleClick}
                                    setActive={setActive}
                                />
                            ))}
                        {!remove_search_functionality && (
                            <Search
                                search_placeholder={search_placeholder}
                                handleClick={setActive}
                            />
                        )}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default Desktop;
