import classNames from 'classnames';
import Button from 'components/Button';
import Image from 'next/legacy/image';
import Link from 'components/Link';
import { navTitles } from 'lib/utils/navTitles';
import Parse from 'react-html-parser';

const Simple = ({ link, active, setActive }) => {
    return (
        <ul
            className={classNames(`navbar__dropdown`, {
                'navbar__dropdown--open': link.id === active,
                'navbar__dropdown--simple': link.style === 'simple',
            })}
        >
            <div
                className={classNames(
                    `navbar__dropdown-container grid-md-${link.links?.length}`
                )}
            >
                {link.links?.map((item, index) => {
                    return (
                        <li className="navbar__dropdown-item" key={index}>
                            <Link
                                className="navbar__dropdown-link"
                                href={item.url}
                                onClick={() => setActive(null)}
                            >
                                <div className="navbar__icon">
                                    {item.icon && (
                                        <Image
                                            className="navbar__icon--normal"
                                            layout="fill"
                                            objectFit="contain"
                                            src={item.icon}
                                        />
                                    )}
                                    {item.icon && (
                                        <Image
                                            className="navbar__icon--luminosity"
                                            layout="fill"
                                            objectFit="contain"
                                            src={item.icon}
                                        />
                                    )}
                                </div>
                                <div className="navbar__title">
                                    {Parse(
                                        item.rich_text_title
                                            ? item.rich_text_title
                                            : navTitles(item.text)
                                    )}
                                </div>
                                {item.cta_text && (
                                    <Button
                                        className={'navbar__dropdown-btn'}
                                        btn_style="tertiary"
                                        element_type="div"
                                        btn_size="sm"
                                        block
                                    >
                                        {Parse(item.cta_text)}
                                    </Button>
                                )}
                            </Link>
                        </li>
                    );
                })}
            </div>
        </ul>
    );
};

export default Simple;
