import Link from 'components/Link';
import classNames from 'classnames';

const NormalCategory = ({ links, split, setActive }) => {
    return (
        <ul
            className={classNames('navbar__category-list', {
                'navbar__category-list--split': split,
            })}
        >
            {[1, 2].map((number) => (
                <div key={number}>
                    {links &&
                        links?.map((item, index) => {
                            const { text, url, col } = item;
                            return (
                                col === number && (
                                    <li
                                        key={index}
                                        className="navbar__category-item"
                                    >
                                        <Link
                                            href={url}
                                            className={'navbar__category-link'}
                                            onClick={() => setActive(null)}
                                        >
                                            {text}
                                        </Link>
                                    </li>
                                )
                            );
                        })}
                </div>
            ))}
        </ul>
    );
};

export default NormalCategory;
