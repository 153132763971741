import Link from 'components/Link';
import classNames from 'classnames';

const SplitCategory = ({ links, split, setActive, line_height }) => {
    return (
        <ul
            className={classNames('navbar__category-list', {
                'navbar__category-list--split': split,
            })}
        >
            {[1, 2].map((number) => (
                <li key={number} className="navbar__category-item mb-0">
                    <ul className="navbar__category-list">
                        {links &&
                            links?.map((item, index) => {
                                const { text, url, col } = item;
                                return (
                                    col === number && (
                                        <li
                                            key={index}
                                            className="navbar__category-item"
                                        >
                                            <Link
                                                href={url}
                                                className={
                                                    'navbar__category-link'
                                                }
                                                onClick={() => setActive(null)}
                                            >
                                                {text}
                                            </Link>
                                        </li>
                                    )
                                );
                            })}
                    </ul>
                </li>
            ))}
        </ul>
    );
};

export default SplitCategory;
