import classNames from 'classnames';
import Link from 'components/Link';

const Basic = ({ link, active, setActive }) => {
    return (
        link.links?.length !== 0 && (
            <ul
                className={classNames(`navbar__dropdown`, {
                    'navbar__dropdown--open': link.id === active,
                    'navbar__dropdown--basic': link.style === 'basic',
                })}
            >
                {link.links?.map((item, index) => {
                    const { text, url } = item;
                    return (
                        <li className="navbar__dropdown-item" key={index}>
                            <Link
                                className="navbar__dropdown-link"
                                href={url}
                                onClick={() => setActive(null)}
                            >
                                {text}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        )
    );
};

export default Basic;
