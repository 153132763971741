import classNames from 'classnames';
import Basic from '../dropdowns/Basic';
import Complex from '../dropdowns/Complex';
import Simple from '../dropdowns/Simple';
import Card from '../dropdowns/Card';
import Link from 'components/Link';
import Parse from 'react-html-parser';
import Icon from 'components/Icon';

const NavItem = ({ link, active, handleClick, setActive }) => {
    return (
        <li
            className={classNames(`navbar__item`, {
                'navbar__item--link':
                    link.style === 'basic' && link.links?.length !== 0,
            })}
            key={link.id}
        >
            {/* Contains subitems */}

            {link.type !== 'page' && (
                <button
                    className={classNames('navbar__link', {
                        'navbar__link--active': link.id === active,
                    })}
                    onClick={(e) => handleClick(e, link.id)}
                    aria-expanded={link.id === active}
                >
                    {link?.title_rich ? Parse(link.title_rich) : link.text}{' '}
                    <Icon
                        wrapperIconClassName={classNames(
                            'navbar__dropdown-arrow',
                            {
                                'navbar__dropdown-arrow--active':
                                    link.id === active,
                            }
                        )}
                        icon="fa-regular fa-chevron-down"
                    />
                </button>
            )}

            {/* Does not contain subitems */}

            {link.type === 'page' && (
                <Link
                    href={link.url}
                    className={classNames('navbar__link', {})}
                    onClick={() => setActive(null)}
                >
                    {link?.rich_text_title
                        ? Parse(link.rich_text_title)
                        : link.text}
                </Link>
            )}
            {/* 4 column with icons */}
            {link.style === 'simple' && (
                <Simple link={link} active={active} setActive={setActive} />
            )}

            {/* 4 column with icons */}
            {link.style === 'card' && (
                <Card link={link} active={active} setActive={setActive} />
            )}

            {/* Multi column with heading and description */}
            {link.style === 'complex' && (
                <Complex link={link} active={active} setActive={setActive} />
            )}

            {/* Normal dropdown */}
            {link.style === 'basic' && (
                <Basic link={link} active={active} setActive={setActive} />
            )}
        </li>
    );
};

export default NavItem;
