import classNames from 'classnames';
import Parse from 'react-html-parser';

const Text = ({ element, theme, content, horizontal_alignment }) => {
    if (!content) return null;
    const Element = element === 'lead' || element === 'sm' ? 'p' : element;
    return (
        <Element
            className={classNames({
                [`text-${theme}`]: theme !== 'default',
                lead: element === 'lead' || element === 'div',
                small: element === 'sm',
                paragraph: element === 'p',
                [`text-${horizontal_alignment}`]:
                    horizontal_alignment === 'right' ||
                    horizontal_alignment === 'center',
            })}
        >
            {Parse(content)}
        </Element>
    );
};

export default Text;
