import NextLink from 'next/link';
import { getUrl } from 'lib/utils/getUrl';
import { useRouter } from 'next/router';

const Link = ({
    children,
    href,
    className,
    onClick,
    disabled,
    type,
    target,
    tabIndex,
    id,
    codename,
    add_button,
    add_button_render_position,
    add_button_insert_position,
    locale,
}) => {
    const router = useRouter();
    if (!href || !children) {
        return null;
    }

    const isExternalLink = href.startsWith('http') || href.startsWith('https');

    const isQueryParamLink = href.startsWith('?');

    const linkProps = {
        className,
        onClick,
        disabled,
        type,
        target: target ? '_blank' : '_self',
        tabIndex,
        'data-kontent-item-id': id,
        'data-kontent-element-codename': codename,
        'data-kontent-add-button': add_button,
        'data-kontent-add-button-render-position': add_button_render_position,
        'data-kontent-add-button-insert-position': add_button_insert_position,
    };

    if (isExternalLink) {
        return (
            <a href={getUrl(href)} {...linkProps}>
                {children}
            </a>
        );
    }

    if (isQueryParamLink) {
        return (
            <a
                href={getUrl(href)}
                {...linkProps}
                onClick={(e) => {
                    e.preventDefault();
                    router.replace(`${router.asPath}${href}`, undefined, {
                        shallow: true,
                    });
                }}
            >
                {children}
            </a>
        );
    }

    return (
        <NextLink href={getUrl(href)} locale={locale} {...linkProps}>
            {children}
        </NextLink>
    );
};

export default Link;
