import Icon from 'components/Icon';
import Parse from 'react-html-parser';
import classNames from 'classnames';
import { Fragment } from 'react';
import Media from 'components/Media';
const List = ({ list, variant, split, color }) => {
    const ListType = variant === 'number' ? 'ol' : 'ul';
    // Set the default color
    if (color === null) {
        if (variant === 'checkmark') {
            color = 'ocean';
        } else if (variant === 'number') {
            color = 'blue';
        } else if (variant === 'cross') {
            color = 'ruby'
        }
    }
    return (
        <ListType
            className={classNames(`list list--${variant}-${color}`, {
                'list--split': split,
            })}
        >
            {list.map((item, index) => {
                return (
                    <Fragment key={index}>
                        {variant !== 'basic' && (
                            <li className="list__item">
                                <div
                                    className={classNames('list__icon', {
                                        'list__icon--media':
                                            variant === 'media',
                                    })}
                                >
                                    {variant === 'number' && <>{index + 1}</>}
                                    {variant === 'checkmark' && (
                                        <Icon icon="fa-solid fa-check" />
                                    )}
                                    {variant === 'cross' && (
                                        <Icon icon="fa-solid fa-xmark" />
                                    )}
                                    {variant === 'media' && item?.media && (
                                        <Media
                                            wrapperClassName={
                                                'list__media-icon'
                                            }
                                            media={item.media}
                                        />
                                    )}
                                </div>
                                <div className="list__content">
                                    <div>{Parse(item.description)}</div>
                                    {item?.separator && (
                                        <div className="list__separator">
                                            {item.separator}
                                        </div>
                                    )}
                                </div>
                            </li>
                        )}
                        {variant === 'basic' && (
                            <li>{Parse(item.description)}</li>
                        )}
                    </Fragment>
                );
            })}
        </ListType>
    );
};

export default List;
